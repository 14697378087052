import * as React from 'react';

import Layout from '../components/core/layout';
import Seo from '../components/core/seo';

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
  </Layout>
);

export default IndexPage;
